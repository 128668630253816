import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/PicPinteres.css";
import { connect } from "react-redux";

const PicPinteres = (props) => {
  const {
    nombre,
    pic,
    url,
    fondo,
    tags,
    fondoActual,
    mobileNombre,
    mobileNombre2,
  } = props;
  const onMouseOver = () => {
    document.body.style.backgroundColor = fondo;
  };

  const onMouseOut = () => {
    document.body.style.backgroundColor = fondoActual;
  };

  return (
    <>
      <div className="PicPinteres">
        <Link to={"/work/" + url}>
          <img
            src={"https://www.pilarzeta.com/PilarSiteMaterials/" + pic}
            alt={nombre}
            // onMouseOver={onMouseOver}
            // onMouseOut={onMouseOut}
          />
          <br /> <br />
          <div className="PicPinteres-tags">
            <span className="resalta">{mobileNombre}</span>
          </div>{" "}
          <br />
          <br />
          <div className="PicPinteres-tags">
            {/* {mobileNombre2} */}
            {/* {tags.map((item, i) => (
              // <Link
              //   to={
              //     "/tag/" +
              //     item.toLowerCase().split(" ").join("-").split(",").join("")
              //   }
              //   key={i}
              // >
              //   <span className="work_tag" key={i}>
              //     {item}
              //   </span>
              // </Link>
            ))}{" "} */}
          </div>
          {/* <div className="tagItemDetails" style={{ backgroundColor: fondo }}>
            <p className="tagItemDetails--nombre">{nombre}</p>
          </div> */}
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fondoActual: state.fondoActual,
  };
};

export default connect(mapStateToProps, null)(PicPinteres);
