import React from "react";
import Poster from "../assets/statics/loadvideo.jpg";
const Videox = props => {
  const { url, heightx } = props;
  return (
    <>
      <video
        src={url}
        autoPlay
        loop
        muted
        playsInline
        width="100%"
        height="auto"
        type="video/mp4"
        poster={url.split(".mov").join(".png")}
        className={heightx}
      ></video>
    </>
  );
};
export default Videox;
