import React from "react";

import KeypadWork from "../components/KeypadWork";
import { connect } from "react-redux";

const More = props => {
  const { children, works, isLoader, yin } = props;

  return (
    <>
      <div className="col-lg-6 col-xl-8">
        <ul className="works" id="works">
          {works.map((item, i) => (
            <KeypadWork key={i} {...item}></KeypadWork>
          ))}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isLoader: state.isLoader,
    works: state.works,
    yin: state.yin
  };
};

export default connect(mapStateToProps, null)(More);
