import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBLPmqztDBbxSqZrMxRtCAsvUHAw5QIq8I",
  authDomain: "pilarzetaapp.firebaseapp.com",
  databaseURL: "https://pilarzetaapp.firebaseio.com",
  projectId: "pilarzetaapp",
  storageBucket: "pilarzetaapp.appspot.com",
  messagingSenderId: "788553796121",
  appId: "1:788553796121:web:59f14878a01cfe3bdeb888",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const dbRef = db.ref("works");
const dbRefARTWORKS = db.ref("ARTWORKS");
const dbRefCOMMERCIAL = db.ref("COMMERCIAL");

export const isLoader = (payload) => ({
  type: "IS_LOADER",
  payload,
});

export const setTagActual = (payload) => ({
  type: "SET_TAG_ACTUAL",
  payload,
});

export const setYin = (payload) => ({
  type: "SET_YIN",
  payload,
});
export const setFondoActual = (payload) => ({
  type: "SET_FONDO_ACTUAL",
  payload,
});

export const getWorksOk = (payload) => ({
  type: "GET_WORKS",
  payload,
});

export const getARTWORKSOk = (payload) => ({
  type: "GET_ARTWORKS",
  payload,
});

export const getCOMMERCIALOk = (payload) => ({
  type: "GET_COMMERCIAL",
  payload,
});

export const getTagOk = (payload) => ({
  type: "GET_TAG",
  payload,
});

export const getWorkOk = (payload) => ({
  type: "GET_WORK",
  payload,
});

export const cleanWork = (payload) => ({
  type: "CLEAN_WORK",
  payload,
});

export const cleanWorks = (payload) => ({
  type: "CLEAN_WORKS",
  payload,
});

export const setUrl = (payload) => ({
  type: "SET_URL",
  payload,
});

let todoListo = false;
let work = "";
// export const getWorks = (dato) => async (dispatch) => {
//   dispatch(isLoader(true));
//   dbRef.on("child_added", (snapshot) => {
//     const item = snapshot.val();
//     dispatch(getWorksOk(item));
//     dispatch(isLoader(false));
//   });
//   setTimeout(function () {
//     todoListo = true;
//   }, 1000);
// };

export const getARTWORKS = (dato) => async (dispatch) => {
  console.log("getARTWORKS()");
  dispatch(isLoader(true));
  dbRefARTWORKS.on("child_added", (snapshot) => {
    const item = snapshot.val();
    dispatch(getWorksOk(item));

    dispatch(getARTWORKSOk(item));
    dispatch(isLoader(false));
  });
  dispatch(getCOMMERCIAL());

  setTimeout(function () {
    todoListo = true;
  }, 1000);
};

export const getCOMMERCIAL = (dato) => async (dispatch) => {
  console.log("getCOMMERCIAL()");

  dispatch(isLoader(true));
  dbRefCOMMERCIAL.on("child_added", (snapshot) => {
    const item = snapshot.val();
    dispatch(getCOMMERCIALOk(item));
    dispatch(getWorksOk(item));
    dispatch(isLoader(false));
  });
  setTimeout(function () {
    todoListo = true;
  }, 1000);
};

export const getWork = (dato) => (dispatch) => {
  dispatch(cleanWork());
  if (todoListo) {
    dispatch(getWorkOk(dato));
  } else {
    setTimeout(function () {
      console.log("dispacho ahora si");
      dispatch(getWorkOk(dato));
    }, 2500);
  }
};
export const portfolio = (dato) => (dispatch) => {
  console.log("ouch portfolio", dato);
  dispatch(cleanWorks());
  if (dato === "ARTWORKS") {
    dbRefARTWORKS.on("child_added", (snapshot) => {
      const item = snapshot.val();
      dispatch(getWorksOk(item));
    });
  }
  if (dato === "COMMERCIAL") {
    dbRefCOMMERCIAL.on("child_added", (snapshot) => {
      const item = snapshot.val();
      dispatch(getWorksOk(item));
    });
  }
};

export const getTag = (dato) => (dispatch) => {
  if (todoListo) {
    dispatch(getTagOk(dato));
    dispatch(setTagActual(dato));
  } else {
    setTimeout(function () {
      dispatch(getTagOk(dato));
      dispatch(setTagActual(dato));
    }, 2500);
  }
};
