import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Videox from "./Videox";

const PicTagDetail = (props) => {
  const { nombre, thumbnail, url, fondo, tipo, imagen, tagActual } = props;
  console.log(tagActual, "lo que trae del tag actual");

  return (
    <>
      {tipo === "img" && tagActual !== "video" && (
        <>
          <div className="col-md-3">
            <div className="tagItem fiximg">
              <Link to={"/work/" + url}>
                <img
                  className="PicTagPic"
                  src={"https://www.pilarzeta.com/" + imagen}
                  alt={nombre}
                />
                <div
                  className="tagItemDetails "
                  style={{ backgroundColor: fondo }}
                >
                  <p className="tagItemDetails--nombre">{nombre}</p>
                </div>
              </Link>
            </div>{" "}
          </div>
        </>
      )}
      {tipo === "video" && (
        <>
          <div className="col-md-3">
            <div className="tagItem">
              <Link to={"/work/" + url}>
                <Videox
                  url={"https://www.pilarzeta.com/" + imagen}
                  heightx={"pepe"}
                ></Videox>

                <div
                  className="tagItemDetails fixv"
                  style={{ backgroundColor: fondo }}
                >
                  <p className="tagItemDetails--nombre">{nombre}</p>
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tagActual: state.tagActual,
  };
};

export default connect(mapStateToProps, null)(PicTagDetail);
