import React from "react";
import ReactDOM from "react-dom";
import App from "./routes/App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/";
import thunk from "redux-thunk";

const initialState = {
  isLoader: true,
  works: [],
  work: [],
  ARTWORKS: [],
  COMMERCIAL: [],
  tag: [],
  url: "",
  fondoActual: "#f3f3f1",
  tagActual: "",
  yin: false,
};

const store = createStore(reducers, initialState, applyMiddleware(thunk));
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
