import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../containers/Home";
import Tag from "../containers/Tag";
import Work from "../containers/Work";
import "../assets/styles/App.css";
import "firebase/database";
import { connect } from "react-redux";
import Info from "../containers/Info";
import Press from "../containers/Press";
import Yinyang from "../containers/Yinyang";
import More from "../containers/More";
import { getARTWORKS } from "../actions/";

const App = (props) => {
  const { getARTWORKS } = props;
  useEffect(() => {
    getARTWORKS();
  }, [getARTWORKS]);

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/more" component={More}></Route>
          <Route path="/tag" component={Tag}></Route>
          <Route path="/work" component={Work}></Route>
          <Route exact path="/info" component={Info}></Route>
          <Route exact path="/press" component={Press}></Route>
          <Route exact path="/yinyang" component={Yinyang}></Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

const mapDispatchToProps = {
  getARTWORKS,
};

export default connect(null, mapDispatchToProps)(App);
