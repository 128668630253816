import React from "react";
import { connect } from "react-redux";
import { setYin, setFondoActual } from "../actions";
import PicPinteres from "../components/PicPinteres";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";

const Yinyang = (props) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };
  props.setFondoActual("#f3f3f1");

  let { works } = props;
  props.setYin(true);

  //document.body.style.backgroundColor = "#f3f3f1";
  const btn = document.getElementById("works");
  if (btn) {
    btn.style.marginLeft = "-560px";
  }
  let layoutx = document.getElementById("layoutx");
  if (layoutx) layoutx.style.display = "block";
  return (
    <>
      <div className="row no-gutter">
        <div className="switchMenu switchMenu-ajuste off">
          <Link to="/">View as List</Link>
          <Link to="/yinyang" className="act">
            View as Images
          </Link>
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid ajustetag"
          columnClassName="my-masonry-grid_column"
        >
          {works.map((item, i) => (
            <PicPinteres {...item}></PicPinteres>
          ))}
        </Masonry>
      </div>{" "}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    works: state.works,
    ARTWORKS: state.ARTWORKS,
    COMMERCIAL: state.COMMERCIAL,
  };
};

const mapDispatchToProps = {
  setYin,
  setFondoActual,
};

export default connect(mapStateToProps, mapDispatchToProps)(Yinyang);
