import React from "react";
import { Link } from "react-router-dom";
import Iconitos from "../assets/statics/icons.svg";

import { connect } from "react-redux";
import { setYin } from "../actions";
import { Helmet } from "react-helmet";
import Pilar from "../assets/statics/Pilar-zeta-bio.jpeg";
const Info = (props) => {
  props.setYin(false);

  //document.body.style.backgroundColor = "#D19CFF";
  const btn = document.getElementById("works");
  if (btn) btn.style.marginLeft = "0px";

  let elms = document.getElementsByClassName("work_name");
  for (var p = 0; p <= elms.length - 1; p++) {
    elms[p].classList.remove("activo");
  }

  return (
    <>
      <Helmet>
        <title>Pilar Zeta - Info</title>
      </Helmet>

      <div className="col-md-9 ajustador">
        <img src={Pilar} alt="Pilar Zeta" className="on img-fluid" /> <br />
        <br />
        <div className="info pre ">
          <p>
            Pilar Zeta, an Argentinian multimedia artist based in Mexico City,
            seamlessly blends elements of philosophy, mathematics, symbolism,
            mysticism, and surrealism, embracing the aesthetics of postmodern
            architecture. Through metaphorical doors, portals, and
            transformative symbols like eggs, she creates surreal spaces that
            connect individuals with their higher selves, opening gateways to
            infinite possibilities and engaging with the depths of the
            subconscious.
          </p>
          <p>
            A remarcable moment in her journey came in 2021 with "Hall of
            Visions," a monumental installation at Miami Art Week held at Faena
            Miami. This immersive masterpiece mesmerized audiences with its
            grandeur. Renowned spiritual leader Deepak Chopra even held a
            meditation session within it, adding a layer of transcendence and
            solidifying the work's significance.
          </p>
          <p>
            Zeta's creative momentum continued in 2022 with two captivating
            exhibitions. "The Space of Variations" at Praz-Delavallade in LA
            delved into the merging of physical and digital worlds through
            quantum physics, paving the way for "Future Transmutation," an
            all-purple Miami Art Week installation that engaged viewers with
            mathematics and occult philosophies. February 2023 saw "Doors of
            Perception" at Galerie Philia during MACO Art Week, where Zeta
            crafted marble-immersive artworks that served as gateways to new
            perceptions.
          </p>
          <p>
            Fulfilling a lifelong dream, Zeta's art journey reached new heights
            in October 2023 with her large-scale "Mirror Gate" installation at
            Art D'Egypt "Forever is Now III." This limestone and metal
            masterpiece, set against the awe-inspiring backdrop of the Giza
            pyramids, embodied her enduring fascination with ancient Egypt.
          </p>
          <p>
            Beyond installations and artworks, Zeta collaborates with prominent
            musical artists like Coldplay, Lil Nas X, Katy Perry, and Camila
            Cabello. Her contributions range from iconic album artwork to
            directing music videos and live performances. Her 2021 Grammy
            nomination for Art Director of Coldplay's "Everyday Life" and Bronze
            Clio Award for directing Camila Cabello's "Don't Go Yet" music video
            are testaments to her multifaceted creative talent.
          </p>
          <p>
            Pilar Zeta's artistic endeavors, deeply rooted in the mysteries of
            ancient Egypt and cosmology, push the boundaries of creative
            expression. Through her visionary approach and captivating
            exploration of the unknown, she continues to inspire audiences
            globally.
          </p>

          <div className="infomobile">
            <p>
              {" "}
              <br />
              <span class="resalta">REPRESENTATION</span>
              <br />
              <br /> ART COMMISSIONS & BRAND COLLABORATIONS <br />
              <a className="ho" href="mailto:jaime@mambocreatives.com">
                jaime@mambocreatives.com
              </a>
              <br />
              <br />
              COMMERCIAL WORK EUROPE <br />
              <a className="ho" href="mailto:jaime@mambocreatives.com">
                jaime@mambocreatives.com
              </a>
              <br />
              <br />
              {/* COMMERCIAL WORK USA <br />
              <a className="ho" href="mailto:cchh@maavven.com">
                cchh@maavven.com
              </a> */}
            </p>
            <p>
              <br />
              <div className="resalta">CONTACT</div>
              <br />
              <br />{" "}
              <a className="ho" href="mailto:pilar@pilarzeta.com">
                pilar@pilarzeta.com
              </a>
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          {/* <span class="resalta">PRESS</span> <br /> <br /> */}
          {/* <div className="press">
            <p>
              <a
                className="linki"
                href="https://www.vogue.com/article/pilar-zeta-moments-of-reality-music-album-postmodern-furniture-instagram-design-artist-escada-style-profile"
                target="_blank"
              >
                Vogue (2019)
              </a>
              <br />
              The Argentinian Artist Whose Colorful Closet and Music Are
              Inspired by Postmodern Furniture.
            </p>
            <p>
              <a
                className="linki"
                href="https://www.flaunt.com/content/pilar-zeta-a-trip-through-the-cosmos"
                target="_blank"
              >
                Flaunt Magazine (2019)
              </a>{" "}
              <br />
              Pilar Zeta: A Trip Through Cosmos <br />
            </p>
            <p>
              <a
                className="linki"
                href="https://www.pilarzeta.com/PilarSiteMaterials/Pilar_Zeta_T-Magazine-Spain.pdf"
                target="_blank"
              >
                T Magazine{" "}
              </a>
              <br />
              At home with Pilar Zeta
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  setYin,
};

export default connect(null, mapDispatchToProps)(Info);
