import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setFondoActual } from "../actions";
import ReactHtmlParser from "react-html-parser";

import Videox from "./Videox";
const Portfolio = (props) => {
  const { portfolio, fondo, nombre } = props;
  props.setFondoActual(fondo);

  // document.body.style.backgroundColor = fondo;

  const createPrograma = () => {
    let table = [];
    let id = 0;
    for (const prop in portfolio) {
      if (portfolio[prop][0] === "txt") {
        let tableChild = [];
        for (const propx in portfolio[prop]) {
          if (portfolio[prop][propx].charAt(0) == "<") {
            let more = portfolio[prop][propx];
            tableChild.push(ReactHtmlParser(more));
          }
          if (propx === "1") {
            tableChild.push(
              <>
                {portfolio[prop][propx]}

                <br />
                <br />
              </>
            );
          } else if (propx !== "0" && portfolio[prop][propx].charAt(0) != "<") {
            tableChild.push(
              <>
                <span key={`hije${propx}${nombre}`}>
                  {portfolio[prop][propx]}
                  <br />
                </span>
              </>
            );
          }
        }

        table.push(tableChild);
      }
      if (portfolio[prop][0] === "video") {
        table.push(
          <>
            <div className="contentVideo" id={`hije${prop}`}>
              <Videox
                key={`hije${prop}${nombre}`}
                url={"https://www.pilarzeta.com/" + portfolio[prop][1]}
              ></Videox>
            </div>
          </>
        );
      }
      if (portfolio[prop][0] === "img") {
        table.push(
          <>
            <div className="contentImg" key={`hije${prop}${nombre}`}>
              <img
                src={"https://www.pilarzeta.com/" + portfolio[prop][1]}
                alt=""
              />
            </div>
          </>
        );
      }
      if (portfolio[prop][0] === "link") {
        table.push(
          <>
            {portfolio[prop][1]}{" "}
            <a
              href={portfolio[prop][3]}
              className="linki"
              target="_blank"
              key={`hije ${prop}`}
            >
              {portfolio[prop][2]}
            </a>
          </>
        );
      }
    }
    return table;
  };

  return (
    <>
      <Helmet>
        <title> {`Pilar Zeta — ${nombre}`}</title>
      </Helmet>

      <div className="info infoOtro" id="resultados">
        {createPrograma()}
        <br />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  setFondoActual,
};

export default connect(null, mapDispatchToProps)(Portfolio);
