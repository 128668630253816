import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/Header.css";
import { connect } from "react-redux";

const KeypadWork = (props) => {
  const { nombre, tags, url, fondo, fondoActual } = props;
  const onMouseOver = () => {
    document.body.style.backgroundColor = fondo;
  };

  const onMouseOut = () => {
    document.body.style.backgroundColor = fondoActual;
  };

  return (
    <>
      <li className="work">
        <Link
          to={"/work/" + url}
          // onMouseOver={onMouseOver}
          // onMouseOut={onMouseOut}
        >
          <h2 className="work_name" url={url}>
            {nombre}
            {"  "}
          </h2>
        </Link>
        {/* <br className="on" /> */}
        {/* <div className="tags-bloque">
          {tags.map((item, i) => (
            <Link
              to={
                "/tag/" +
                item
                  .toLowerCase()
                  .split(" ")
                  .join("-")
                  .split(",")
                  .join("")
              }
              key={i}
            >
              <span className="work_tag" key={i}>
                {item}
              </span>
            </Link>
          ))}{" "}
        </div> */}
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fondoActual: state.fondoActual,
  };
};

export default connect(mapStateToProps, null)(KeypadWork);
