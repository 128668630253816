import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getWork, setYin } from "../actions";
import Portfolio from "../components/Portfolio";

const Work = (props) => {
  props.setYin(false);

  const activo = () => {
    let elms = document.getElementsByClassName("work_name");
    for (var p = 0; p <= elms.length - 1; p++) {
      elms[p].classList.remove("activo");
      let v = elms[p].getAttribute("url");
      if (v === url) {
        elms[p].classList.add("activo");
      }
    }
  };
  const { history, work } = props;
  let url = "";
  useEffect(() => {
    url = history.location.pathname.substr(6);
    props.getWork(url);
    window.scrollTo(0, 0);
    activo();
  }, [getWork, history]);
  url = history.location.pathname.substr(6);
  const btn = document.getElementById("works");
  if (btn) btn.style.marginLeft = "0px";
  if (work[0]) {
    if (url !== work[0].url) {
      props.getWork(url);
      window.scrollTo(0, 0);
      activo();
    }
  }

  return (
    <>
      <div className="col-md-9 ajustador newajusta">
        {work && (
          <>
            <Portfolio {...work[0]} key="pepe"></Portfolio>
          </>
        )}
        <br />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    work: state.work,
  };
};

const mapDispatchToProps = {
  getWork,
  setYin,
};
export default connect(mapStateToProps, mapDispatchToProps)(Work);
