import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "../assets/styles/Header.css";
import { portfolio } from "../actions";

import Botonera from "../assets/statics/botonera.svg";
import Ying from "../assets/statics/yinyang.svg";

const Header = (props) => {
  let history = useHistory();
  let iraportfolio = (e) => {
    let contenedor = document.getElementById("botoneraMobile");
    let elements = contenedor.getElementsByClassName("coloritoA");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("activoM");
      console.log(elements[i].innerHTML);
    }

    e.target.classList.add("activoM");
    let btn = e.target.getAttribute("name");
    if (btn === "info") {
      history.push("/info");
    } else {
      props.portfolio(btn);
      history.push("/yinyang");

      // setTimeout(function () {
      //   history.push("/yinyang");
      // }, 500);
    }
  };

  return (
    <>
      <header className="header">
        <ul className="miniLinks on botoneraMobile" id="botoneraMobile">
          <li>
            <Link to="/" className="on log homeMobile ">
              PILAR ZETA
            </Link>
          </li>

          <li>
            <div className="coloritoA" name="ARTWORKS" onClick={iraportfolio}>
              ARTWORKS & COMMISSIONS
            </div>
          </li>

          <li>
            <div className="coloritoA" name="COMMERCIAL" onClick={iraportfolio}>
              COMMERCIAL WORK
            </div>
          </li>
          <li>
            <div
              className="coloritoA infoMobile"
              name="info"
              onClick={iraportfolio}
            >
              INFO
            </div>
          </li>
        </ul>
        <Link to="/" className="off coloritoB log">
          PILAR ZETA
        </Link>

        <ul className="miniLinks">
          {/* <li>
            <Link to="/yinyang" className="coloritoA off">
              {" "}
              Work
            </Link>
          </li> */}
          <li>
            <Link to="/info" className="coloritoB off">
              {" "}
              INFO
            </Link>
          </li>
          {/* <li>
            <Link to="/press" className="coloritoC">
              Press
            </Link>
          </li> */}
        </ul>
        {/* <Link to="/more" className="on">
          <img src={Botonera} alt="" className="btnmobile" />
        </Link> */}
      </header>
    </>
  );
};

const mapDispatchToProps = {
  portfolio,
};

export default connect(null, mapDispatchToProps)(Header);
