const reducer = (state, action) => {
  switch (action.type) {
    case "GET_WORKS":
      console.log(action.payload);

      return {
        ...state,
        works: [...state.works, action.payload],
      };

    case "GET_ARTWORKS":
      console.log(action.payload);
      return {
        ...state,
        ARTWORKS: [...state.ARTWORKS, action.payload],
      };

    case "GET_COMMERCIAL":
      console.log(action.payload);
      return {
        ...state,
        COMMERCIAL: [...state.COMMERCIAL, action.payload],
      };

    case "UPDATE_WORK":
      return {
        ...state,
        work: state.works.filter((items) => items.url === action.payload),
      };
    case "SET_URL":
      return {
        ...state,
        url: action.payload,
      };

    case "GET_TAG":
      console.log("GET_TAG", action.payload);
      return {
        ...state,
        tag: state.works.filter((items) =>
          items.tags.some(
            (elem) => elem.toLowerCase().split(",").join("") == action.payload
          )
        ),
      };

    case "GET_WORK":
      console.log("GET_WORK");
      return {
        ...state,
        work: state.works.filter((items) => items.url === action.payload),
      };

    case "CLEAN_WORK":
      console.log("CLEAN_WORK");
      return {
        ...state,
        work: [],
      };

    case "CLEAN_WORKS":
      console.log("CLEAN_WORKS");
      return {
        ...state,
        works: [],
      };
    case "SET_YIN":
      return {
        ...state,
        yin: action.payload,
      };
    case "IS_LOADER":
      return {
        ...state,
        isLoader: action.payload,
      };

    case "SET_TAG_ACTUAL":
      return {
        ...state,
        tagActual: action.payload,
      };

    case "SET_FONDO_ACTUAL":
      return {
        ...state,
        fondoActual: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
