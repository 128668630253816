import React from "react";
// import LoaderImg from "../assets/statics/loader.gif";

import "../assets/styles/Loader.css";
const Loader = (props) => {
  return (
    <>
      <div className="loader-wraper"></div>
    </>
  );
};

export default Loader;
