import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getTag, setUrl, setYin } from "../actions";
import PicTagDetail from "../components/PicTagDetail";
import Masonry from "react-masonry-css";
import PicPinteres from "../components/PicPinteres";
import { Link } from "react-router-dom";

const Tag = props => {
  const { history, work, tag, url } = props;
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
  };
  props.setYin(true);
  let urlx = "";
  useEffect(() => {
    urlx = history.location.pathname.substr(5);
    props.setUrl(urlx);
    let urloki = urlx
      .toLowerCase()
      .split("-")
      .join(" ");
    props.getTag(urloki);
  }, [getTag, history, setUrl]);

  if (url !== "") {
    urlx = history.location.pathname.substr(5);
    if (url !== urlx) {
      props.setUrl(urlx);
      let urloki = urlx
        .toLowerCase()
        .split("-")
        .join(" ");
      props.getTag(urloki);
    }
  }

  //document.body.style.backgroundColor = "#f3f3f1";
  const btn = document.getElementById("works");

  if (btn) {
    btn.style.marginLeft = "-560px";
  }
  let layoutx = document.getElementById("layoutx");
  if (layoutx) layoutx.style.display = "block";
  return (
    <>
      <div className="row no-gutter">
        <div className="switchMenu switchMenu-ajuste off">
          <Link to="/" className="">
            View as List
          </Link>
          <Link to="/yinyang">View as Images</Link>
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid ajustetag"
          columnClassName="my-masonry-grid_column"
        >
          {tag.map((item, i) => (
            <PicPinteres {...item}></PicPinteres>
          ))}
        </Masonry>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    tag: state.tag,
    url: state.url
  };
};

const mapDispatchToProps = {
  getTag,
  setUrl,
  setYin
};

export default connect(mapStateToProps, mapDispatchToProps)(Tag);
