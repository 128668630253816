import React from "react";
import Iconitos from "../assets/statics/icons.svg";
import { connect } from "react-redux";
import { setYin } from "../actions";
const Press = (props) => {
  props.setYin(false);

  //document.body.style.backgroundColor = "#DDD200";
  const btn = document.getElementById("works");
  if (btn) btn.style.marginLeft = "0px";
  let elms = document.getElementsByClassName("work_name");
  for (var p = 0; p <= elms.length - 1; p++) {
    elms[p].classList.remove("activo");
  }

  return (
    <>
      <div className="col-lg-6 col-xl-8 ajustador">
        <div className="info pre">
          <p>
            Vogue (2019)
            <br /> <br />
            The Argentinian Artist Whose Colorful Closet and <br />
            Music Are Inspired by Postmodern Furniture. <br />
            Read Interview →{" "}
            <a
              className="linki"
              href="https://www.vogue.com/article/pilar-zeta-moments-of-reality-music-album-postmodern-furniture-instagram-design-artist-escada-style-profile"
              target="_blank"
            >
              Here
            </a>
          </p>
          <p>
            <br />
            <strong>Flaunt Magazine (2019) </strong> <br /> <br />
            Pilar Zeta: A Trip Through Cosmos <br />
            Read Interview →{" "}
            <a
              className="linki"
              href="https://www.flaunt.com/content/pilar-zeta-a-trip-through-the-cosmos"
              target="_blank"
            >
              Here
            </a>
          </p>
          <p>
            <br />
            <strong>T Magazine (2018)</strong> <br /> <br />
            At home with Pilar Zeta <br />
            Read Interview →{" "}
            <a
              className="linki"
              href="https://www.pilarzeta.com/PilarSiteMaterials/Pilar_Zeta_T-Magazine-Spain.pdf"
              target="_blank"
            >
              Here
            </a>
          </p>
          <br />
          <p>
            <strong>Apartment Therapy (2018) </strong> <br />
            <br />
            The Dream of the 90’s is alive <br />
            in Pilar Zeta’s home <br />
            Read Interview →{" "}
            <a
              className="linki"
              href="https://www.apartmenttherapy.com/artist-pilar-zetas-portland-home-tour-265730"
              target="_blank"
            >
              Here
            </a>
          </p>
          <p>
            {" "}
            <br />
            <strong>Coldplay.com (2016) </strong> <br /> <br />
            Pilar Zeta: AHFOD Artwork . Creator <br />
            Read Interview →{" "}
            <a
              className="linki"
              href="http://www.coldplay.com/interview-pilar-zeta-ahfod-artwork-creator/"
              target="_blank"
            >
              Here
            </a>
          </p>
          <br />
          <img src={Iconitos} alt="" />
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = {
  setYin,
};

export default connect(null, mapDispatchToProps)(Press);
