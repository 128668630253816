import React from "react";
import { connect } from "react-redux";

import Ying from "../assets/statics/yinyang.svg";
import { Link } from "react-router-dom";
import { setYin } from "../actions";

const Footer = (props) => {
  const { yinyyang, works, fondoActual } = props;
  const handleClick = () => {
    props.setYin(false);
  };

  let isOver = false;
  let myVar;

  let fondoCount = 0;

  const myFunction = () => {
    if (fondoCount < 15) {
      fondoCount++;
    } else {
      fondoCount = 0;
    }

    if (isOver) {
      document.body.style.backgroundColor = works[fondoCount].fondo;
      myVar = setTimeout(myFunction, 100);
    }
  };

  const onMouseOver = () => {
    isOver = true;
    myFunction();
  };

  const onMouseOut = () => {
    isOver = false;
    document.body.style.backgroundColor = fondoActual;
  };

  const onClick = () => {
    isOver = false;
    document.body.style.backgroundColor = fondoActual;
  };

  return (
    <>
      <footer className="">
        {/* {yinyyang ? (
          <>
            <div className="centradino">
              <Link to="/" onClick={handleClick}>
                <img src={Ying} alt="" className="Yin" />
              </Link>
              <Link to="/" onClick={handleClick}>
                <p id="#volver" className="volver off">
                  {"    "}←{"  "} Back to List
                </p>
              </Link>
            </div>
          </>
        ) : (
          <>
            <Link
              to="/yinyang"
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={onClick}
            >
              <img src={Ying} alt="" className="Yin" />
            </Link>
          </>
        )} */}
        <a href="https://www.instagram.com/pilar_zeta/" className="instax off">
          <span>
            𓁿 <br /> 𓊍
          </span>
        </a>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    works: state.works,
    fondoActual: state.fondoActual,
  };
};
const mapDispatchToProps = {
  setYin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
