import React from "react";
import Loader from "./Loader";
import Header from "./Header";
import Footer from "./Footer";
import KeypadWork from "../components/KeypadWork";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Layout = (props) => {
  const { children, works, isLoader, yin, COMMERCIAL, ARTWORKS } = props;

  return (
    <>
      {isLoader && <Loader></Loader>}
      <Header></Header>
      <div className="works off col-md-2" id="works">
        <div className="switchMenu">
          <Link to="/" className="act">
            ARTWORKS & COMMISSIONS
          </Link>
        </div>

        <ul className="worksContent">
          {ARTWORKS.map((item, i) => (
            <KeypadWork key={`${item.nombre}${i}`} {...item}></KeypadWork>
          ))}
        </ul>
        <div className="">
          <Link
            to="/"
            className="act"
            style={{ "padding-bottom": "3px", "margin-left": "3px" }}
          >
            COMMERCIAL WORK
          </Link>
        </div>
        <ul className="worksContent">
          {COMMERCIAL.map((item, i) => (
            <KeypadWork key={`${item.nombre}${i}`} {...item}></KeypadWork>
          ))}
        </ul>
      </div>
      <div className="container-fluid" id="layoutx">
        <div className="row">
          <div className="col-md-3"></div>
          {children}
        </div>
      </div>
      <Footer yinyyang={yin}></Footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoader: state.isLoader,
    works: state.works,
    ARTWORKS: state.ARTWORKS,
    COMMERCIAL: state.COMMERCIAL,
    yin: state.yin,
  };
};

export default connect(mapStateToProps, null)(Layout);
