import React from "react";
import "video-react/dist/video-react.css";
import { connect } from "react-redux";
import { setYin, setFondoActual } from "../actions";
import Videox from "../components/Videox";

const Home = (props) => {
  props.setYin(false);
  props.setFondoActual("#f3f3f1");
  //document.body.style.backgroundColor = "#f3f3f1";
  const btn = document.getElementById("works");
  if (btn) btn.style.marginLeft = "0px";
  if (btn) btn.style.width = "100%";

  // if (parseInt(window.screen.availWidth) > 1080) {
  // } else {
  //   window.location = "./yinyang";
  // }

  let elms = document.getElementsByClassName("work_name");
  for (var p = 0; p <= elms.length - 1; p++) {
    elms[p].classList.remove("activo");
  }
  let layoutx = document.getElementById("layoutx");
  return (
    <>
      <div className="col-md-9 ajustador">
        {/* <img
          src="https://media.giphy.com/media/Vho4BSWh8VfgmPTc8S/giphy.gif"
          alt=""
          className="nuevo"
        /> */}
        {/* <Videox
          url={"http://pilarzeta.com//PilarSiteMaterials/intro-video-final.mov"}
          heightx={"videoHome"}
        ></Videox> */}
        <a href="/work/hall-of-visions-faena-art">
          <img
            src="https://www.pilarzeta.com/PilarSiteMaterials/portada2024.jpeg"
            alt=""
            className="img-fluid off"
          />
        </a>
      </div>
      <a href="/work/hall-of-visions-faena-art">
        <Videox
          url={"http://pilarzeta.com//PilarSiteMaterials/home_video.mp4"}
          heightx={"videoHOMELOCO on"}
          className="on videoHOMELOCO"
        ></Videox>
      </a>
    </>
  );
};
const mapDispatchToProps = {
  setYin,
  setFondoActual,
};

export default connect(null, mapDispatchToProps)(Home);
